import {getRole} from "@/services/StorageService";

export enum Roles {
    PATIENT = "PATIENT",
    PRACTITIONER = "PRACTITIONER",
    RELATIVE = "RELATIVE"
}

export async function getUserRole() {
    return await getRole();
}

export async function roleIsPractitioner() {
    const role = await getRole();
    return role === Roles.PRACTITIONER.valueOf()
}

export async function roleIsPractitionerOrPatient() {
    const role = await getRole();
    return role === Roles.PRACTITIONER.valueOf() ||
        role === Roles.PATIENT.valueOf()
}