import router from "@/router";
import {getItem, removeItem, setItem} from "@/stores/storage/Storage";

export async function isAuthenticated(): Promise<boolean> {
    const isLoggedIn = await getItem('logged_in');
    return ((isLoggedIn !== null) && isLoggedIn === "true")
}

export async function getUser(): Promise<string> {
    const user = await getItem('user')
    return user ? user : '';
}

export async function getRole(): Promise<string> {
    const role = await getItem('role')
    return role ? role : '';
}

export async function saveUser(user: string, role: string) {
    await setItem('user', user)
    await setItem('role', role)
    await setItem('logged_in', "true")
}

export async function logout() {
    await removeItem('logged_in')
    await router.push({name: 'Login', replace: true})
}