<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" :hidden="isHidden">
        <slot></slot>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts" setup>
import { IonTabBar, IonTabs, IonPage, IonRouterOutlet } from '@ionic/vue';
import {onMounted, ref} from "vue";

const isHidden = ref(false)

onMounted(() => {
  addNavigationListeners();
})

function addNavigationListeners() {
  window.addEventListener('digipal:navigation:hide', () => {
    isHidden.value = true;
  });
  window.addEventListener('digipal:navigation:show', () => {
    isHidden.value = false;
  });
}
</script>

<style lang="scss" scoped>
ion-router-outlet {
  height: 100vh;
}

ion-tab-bar {
  width: 100%;
  height: 80px;
  border-radius: 20px 20px 0 0;
  background-color: $blue-saphire-dark;
}
</style>