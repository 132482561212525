import { Preferences } from '@capacitor/preferences';

export async function setItem(key: string, value: string) {
    await Preferences.set({ key: key, value: value });
}

export async function getItem(key: string): Promise<string> {
    const ret = await Preferences.get({ key: key });
    if (typeof ret.value === "string") {
        return ret.value;
    } else {
        return "";
    }
}

export async function removeItem(key: string) {
    await Preferences.remove({ key: key });
}