import { createApp } from 'vue'
import { createPinia } from 'pinia';
import { plugin, defaultConfig } from '@formkit/vue'
import App from './App.vue'
import router from './router';
import './registerServiceWorker';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/fonts.scss'
import './theme/forms.scss'
import './theme/main.scss'

import '@formkit/themes/genesis'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import fkConfig from '../formkit.config'

export const pinia = createPinia();

const pallTheme = {
  dark: false,
  colors: {
    primary: '#05668d',
    secondary: '#69a3bb',
  },
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'pallTheme',
    themes: { pallTheme }
  },
  defaults: {
    VBtn: {
      color: 'primary',
      size: 'x-large'
    }
  }
})

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(pinia)
  .use(vuetify)
  .use(plugin, defaultConfig(fkConfig))
  // .use(Vuex);
  
router.isReady().then(() => {
  app.mount('#app');
});